import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4CAF50",
    },
    secondary: {
      main: "#0092BC",
    },
    // background: {
    //   default: "#f1f1f1",
    // },
  },
  typography: {
    useNextVariants: true,
    // fontFamily: ["Lato", "sans-serif"].join(","),
  },
})

export default theme
